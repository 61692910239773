<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <template>
              <div class="text-center text-muted mb-4 h4">
                <small>Logg inn</small>
              </div>
              <div
                role="alert"
                v-if="showWrongPassword"
                class="alert alert-danger"
              >
                <strong>Oisann!</strong> Kombinasjonen av mobilnummer og passord
                finnes ikke i systemet
              </div>
              <form role="form">
                <base-input
                  alternative
                  ref="mobil"
                  class="mb-3"
                  placeholder="Mobilnummer"
                  addon-left-icon="fa fa-user"
                  v-model="username"
                  @keyup.enter="moveToPassword"
                ></base-input>
                <base-input
                  alternative
                  type="password"
                  ref="passord"
                  placeholder="Passord"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="password"
                  @keyup.enter="onSubmit"
                ></base-input>
                <div class="text-center">
                  <base-button
                    type="primary"
                    v-if="!showLoading"
                    class="my-4"
                    @click="onSubmit"
                    >Logg inn</base-button
                  >
                  <base-button type="primary" v-if="showLoading" class="my-4">
                    <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                  </base-button>
                </div>
              </form>
            </template>
          </card>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/forgot" class="text-light">
                <small>Glemt passord?</small>
              </router-link>
            </div>
            <div class="col-6 text-right">
              <router-link to="/register" class="text-light">
                <small>Har du ikke konto?</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      showWrongPassword: false,
      showLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => this.$refs.mobil.$el.children[1].focus());

    console.log("Redir", this.$route.query.redirect);
  },
  methods: {
    moveToPassword() {
      this.$nextTick(() => this.$refs.passord.$el.children[1].focus());
    },
    onSubmit() {
      this.showLoading = true;
      const formData = {
        username: this.username,
        password: this.password,
      };
      this.$store
        .dispatch("login", {
          username: formData.username,
          password: formData.password,
        })
        .then(
          (res) => {
            if (res == 1) {
              this.showWrongPassword = false;
              //this.$router.push("/mypage");
              const redirectUrl = this.$route.query.redirect || "/mypage";
              this.$router.push(redirectUrl);
            }
            if (res == 0) {
              this.showWrongPassword = true;
            }
            this.showLoading = false;
            console.log(res);
          },
          (error) => {
            console.log(error);

            this.showLoading = false;
          }
        );
    },
  },
};
</script>
<style>
</style>
