<template >
  <footer class="footer">
    <div v-show="false" class="container container-lg">
      <div class="row">
        <div class="col-md-6 mb-5 mb-md-0">
          <div class="card card-lift--hover shadow border-0">
            <router-link to="/landing" title="Landing Page">
              <img v-lazy="'/img/theme/landing.jpg'" class="card-img" />
            </router-link>
          </div>
        </div>
        <div class="col-md-6 mb-5 mb-lg-0">
          <div class="card card-lift--hover shadow border-0">
            <router-link to="/profile" title="Profile Page">
              <img v-lazy="'/img/theme/profile.jpg'" class="card-img" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div v-show="false" class="row row-grid align-items-center my-md">
        <div class="col-lg-6">
          <h3 class="text-primary font-weight-light mb-2">
            Thank you for supporting us!
          </h3>
          <h4 class="mb-0 font-weight-light">
            Let's get in touch on any of these platforms.
          </h4>
        </div>
        <div class="col-lg-6 text-lg-center btn-wrapper">
          <a
            target="_blank"
            rel="noopener"
            href="https://twitter.com/creativetim"
            class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <i class="fa fa-twitter"></i>
          </a>
          <a
            target="_blank"
            rel="noopener"
            href="https://www.facebook.com/creativetim"
            class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Like us"
          >
            <i class="fa fa-facebook-square"></i>
          </a>
          <a
            target="_blank"
            rel="noopener"
            href="https://dribbble.com/creativetim"
            class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round"
            data-toggle="tooltip"
            data-original-title="Follow us"
          >
            <i class="fa fa-dribbble"></i>
          </a>
          <a
            target="_blank"
            rel="noopener"
            href="https://github.com/creativetimofficial"
            class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Star on Github"
          >
            <i class="fa fa-github"></i>
          </a>
        </div>
      </div>
      <hr />
      <div class="row align-items-center justify-content-md-between">
        <div class="col-sm-4">
          <div class="copyright">
            &copy; {{ year }}
            <a href="https://www.aspelund.no" target="_blank" rel="noopener"
              >K. Aspelund AS</a
            >
          </div>
        </div>
        <div class="col-sm-8">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <a
                href="https://hjelpelinjen.no"
                target="_blank"
                class=""
                title="Hjelpelinjen"
              >
                <img
                  class="card-img"
                  data-src="/img/brand/Hjelpelinjen.png"
                  src="/img/brand/Hjelpelinjen.png"
                  lazy="loaded"
                />
              </a>
            </li>
            <li class="nav-item ml-3">
              <img
                class="card-img"
                data-src="/img/brand/18aar.png"
                src="/img/brand/18aar.png"
                lazy="loaded"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>
</style>
