<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0">
            <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--500" no-body>
                    <div class="m-4">
                        <h4>Bingo, et morsomt og sosialt spill som er enkelt å lære!</h4>
                        <h5>Stor bingobong</h5>
                        <p>Når du bestiller en stor bong får du tre spillefelt (ruter) på skjermen der hvert spillefelt inneholder 25 tall. Totalt inneholder en stor bong tallene fra 1-75. Veldig ofte spiller vi med såkalte freeplay bonger, hvilket betyr at tallet midt på spillefeltet er skjult.</p>
                        <p>Tallet som er dekket til er et «gratistall» slik at dersom du får de fire andre tallene på den aktuelle raden så har du bingo!</p>
                        <p>Øverst på bongen, over hver kolonne, står det B-I-N-G-O. Selve trekningen foregår ved at det trekkes tall fra 1 til 75. På bongen vil det altså være denne fordelingen:
                            B = 1-15 
                            I = 16-30 
                            N = 31-45 
                            G = 46-60 
                            O = 61-75 
                        </p>
                        <p>Når for eksempel tallet 12 kommer opp på skjermen, betyr det at tallet finnes i kolonnen B. Er det tallet 48 som kommer opp finner du det i kolonnen G. </p>
                        <p>Du avgjør selv hvor mange bonger du ønsker å kjøpe til hvert spill ved å bruke knappene på bestillingsmenyen nede i høyre hjørne på skjermen etter at du har åpnet hovedspillet.</p>
                                                <h5>Liten bingobong</h5>
                        <p>Ved enkelte spill (ofte kalt Kvikkbingo) består hver bong av kun ett spillefelt med 25 tall. Selve bongen er som regel lik den som er beskrevet ovenfor. </p>
                                                <h5>Under selve spillet</h5>
                        <p>I de fleste spill vinner du på vannrette rader, fra en til fem rader. Man spiller om en vannrett rad av gangen. Når man har fått bingo på en rad, spiller man om to rader, osv, og til slutt spiller man om fem rader. Din beste bong vil alltid bli flyttet opp til øvre venstre hjørne. Det vil også stå på bongen hvor mange tall du mangler til gevinst. Gevinster utbetales i form av pengepremier og overføres til din spillekonto.</p>
                        <p>Alle radene må oppnås innenfor ett og samme spillefelt (rute på bongen) for å få gevinst. Når det aktuelle tallet ropes opp, ser du allerede det neste tallet på skjermen. Det hjelper deg å sjekke hvordan du ligger an til å få bingo. </p>
                        <p>Lykke til!</p>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>


export default {


    
};
</script>
<style>
</style>
