<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0">
            <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--500" no-body>
                    <div class="m-4">
                        <h3>{{newsfeed[$route.params.id].title}}</h3>
<img v-if="newsfeed[$route.params.id].img" style="float:left;margin-right:10px;margin-bottom:10px" v-lazy="newsfeed[$route.params.id].img">
                        <p  v-html="getBRIngress($route.params.id)"></p>
                        <p  v-html="getBRBody($route.params.id)"></p>

                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>


export default {

  data () {

      return {
          newsfeed: [
              { 
                  img: "http://www.norcasino.com/wp-content/uploads/2014/01/online_bingo-300x257.jpg",
                  title: "Lorem Ipsum",
                  ingress: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tristique ligula suscipit turpis scelerisque, ac finibus nunc consequat.",
                  body: "Mauris sodales diam ex, non ultricies nulla suscipit vitae. Nunc laoreet metus libero, ac dapibus libero aliquet non. Ut ullamcorper condimentum turpis euismod ullamcorper. Donec maximus justo velit, sit amet fringilla diam iaculis at. Etiam id risus id tortor mattis vehicula. Vestibulum aliquet sollicitudin sem, nec vehicula dolor auctor eu. Curabitur elementum ullamcorper bibendum. Fusce efficitur turpis ac justo pretium, vitae condimentum augue aliquet. Vivamus lacinia eros aliquet, gravida orci vitae, auctor nunc. Nullam egestas, diam sit amet molestie maximus, dolor eros convallis odio, dictum finibus tellus tellus sit amet magna. Vivamus ipsum felis, pretium vel lacinia ut, maximus ut est."
              },
              { 
                  title: "LYNBINGO",
                  ingress: "Lynbingo kjører på små 3x3 bonger hvor man trekker tallene 1 til 21. Spillene kan spilles elektronisk på terminaler i hallen, og på dinbingo.no.",
              }

          ],
          getBRBody: function (id) {
            const doc = this.newsfeed[id].body;
            return doc.replaceAll(/\\n/g, '<br>');
          },
          getBRIngress: function (id) {
            const doc = this.newsfeed[id].ingress;
            return doc.replaceAll(/\\n/g, '<br>');
          },
      }

   },



    
};
</script>
<style>
table, th, td {
    padding: 8px;
    text-align: right;
}
</style>
