<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0">
            <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--500" no-body>
                    <div class="m-4">
                        <h4>Nyheter i Hovedspill 2!</h4>
                        <ul>
                            <li>Man vil nå kunne spille på 5, 10 og 20 kr bonger.</li>
                            <li>10 kr bong gir dobbel gevinst.</li>
                            <li>20 kr bong gir gevinst x 4.</li>
                            <li>Diagonal spillet har fått en jackpott. Det gis 10.000, kr for midtrute oppnådd på 4 trekk (20 kr bong), og 2500 på 5 trekk.  Fra 6 trekk og utover gis vanlig rekkegevinst.</li>
                        </ul>
                        <p>Jackpott ved oppnådd midtrute innen 4 eller 5 trekk:</p>
                        <table>
                            <tr>
                                <th style="text-align:center">Ant trekk</th>
                                <th>5kr</th>
                                <th>10kr</th>
                                <th>20kr</th>
                            </tr>
                            <tr>
                                <td  style="text-align:center">4</td>
                                <td>2 500</td>
                                <td>5 000</td>
                                <td>10 000</td>
                            </tr>
                            <tr>
                                <td  style="text-align:center">5</td>
                                <td>625</td>
                                <td>1 250</td>
                                <td>2 500</td>
                            </tr>

                        </table>
                        <p style="margin-top:20px;">10 kr bong gir 2 x gevinst i forhold til 5 kr bong.</p>
                        <p>20 kr bong gir 4 x gevinst i forhold til 5 kr bong.</p>
                        <h6 style="margin-top:20px;font-weight:bold">Prisen på bongene vises øverst i venstre hjørne</h6>
                        <img v-lazy="'img/brand/bongmedpris.png'" style="border-bottom: 1px solid black;">
                         <h5 style="margin-top:20px;font-weight:bold;font-style:italic">Vi ønsker deg lykke til!</h5>

                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>


export default {


    
};
</script>
<style>
table, th, td {
    padding: 8px;
    text-align: right;
}
</style>
