<template>
  <div class="card">
    <div class="card-body">
     <div class="row">
       <div class="col-12 col-lg-2">
        <img v-if="hall.BingoLogo" :src="'img/brand/logo/' + hall.BingoLogo" />
       </div>
       <div class="col-12 col-lg-5 pl-0">
         <div class="col-12 font-weight-bold">
      {{ hall.BingoName }}
         </div>
         <div class="col-12">
      {{ hall.BingoAddress }}
         </div>
       </div>
      </div> 
    </div>
  </div>
</template>
<script>
export default {
    name: "bingo-hall",
    props: {
        hall: {
            type: Object,
            default: null,
            description: "Bingo Data"
        }

    },
    methods: {

    }
}
</script>