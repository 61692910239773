<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-primary shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">

                    <div class="col px-0">
                                                              <div class="alert alert-success" style="text-shadow: 1px 1px 1px black;" role="alert" v-if="true == false">
                    <strong>Velkommen nye spillere!</strong> Det er nå mulig å registrere seg som ny spiller uten personlig oppmøte.<br>Registrer deg nedenfor, velg din lokale bingosalong, så er du i gang!
                  </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">Spill bingo på nett
                                    <span>hos din lokale bingohall!</span>
                                </h1>
                                <p class="lead  text-white">Det er endelig åpnet for bingospill på nett.<br>Du støtter de samme lokale lag og foreninger, som du gjør ved spill i bingolokalet.</p>
                                <div class="btn-wrapper" v-if="!this.$store.getters.isAuthenticated">

                                    <router-link to="/login" class="mb-3 mb-sm-0">
                                    <base-button 
                                                 
                                                 type="white"
                                                 icon="fa fa-sign-in">
                                        Logg inn
                                    </base-button>
                                    </router-link>
                                    <router-link to="/register" class="mb-3 mb-sm-0 pl-3">
                                    <base-button 
                                                 
                                                 type="success"
                                                 icon="fa fa-user-plus">
                                        Registrer deg
                                    </base-button>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-0" style="margin-top: -200px !important;">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid container">
                            <div class="col-lg-4 col-12 d-flex align-items-stretch">
                                <card class="border-0 flex-fill" hover shadow body-classes="py-4">
                                    <icon type="primary" rounded class="mb-4 h5 font-weight-bold">1
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Registrer deg</h6>
                                    <p class="description mt-3">Du må oppsøke din lokale bingohall for å registrere deg. Under informasjon øverst på siden, kan du finne din lokale bingohall.</p>
                                </card>
                            </div>
                            <div class="col-lg-4 col-12 d-flex align-items-stretch">
                                <card class="border-0 flex-fill" hover shadow body-classes="py-4">
                                    <icon type="success" rounded class="mb-4 h5 font-weight-bold">2
                                    </icon>
                                    <h6 class="text-success text-uppercase">Innskudd</h6>
                                    <p class="description mt-3">Du kan velge om du vil gjøre innskudd på bingohallen, eller via vår sikre betalingsløsning, med bankid.</p>
                                </card>
                            </div>
                            <div class="col-lg-4 col-12 d-flex align-items-stretch">
                                <card class="border-0 flex-fill" hover shadow body-classes="py-4">
                                    <icon type="warning" rounded  class="mb-4 h5 font-weight-bold">3
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Spill på nett</h6>
                                    <p class="description mt-3">Du er nå klar for å spille på nett. Du kan spille og forhåndskjøpe bonger.</p>
                                </card>
                            </div>
                        </div>
                    </div>

                </div>

            <div class="alert alert-default" style="text-shadow: 1px 1px 1px black;margin-right:25px;margin-top:40px" role="alert" v-if="true == false">
             <strong>Nyheter i Hovedspill 2!</strong> Flere endringer i H2 spillet <strong><router-link style="color:white;" to="/newsh2">Les mer...</router-link></strong>

            </div>

            </div>


        </section>
        <section class="section"><div class="container">


                                <div class="col-md-12 mb-3"  v-for="article in newsfeed" v-bind:key="article.id" >
                        <div class="card bg-white shadow border-0">
                              <div class="row no-gutters">
                                <div v-if="article.img" class="col-md-4">
                            <img v-lazy="article.img" class="card-img-top">

                                </div>
    <div v-bind:class="[article.img ? 'col-md-8' : 'col-md-12']">
      <div class="card-body">
        <h5 class="card-title display-4">{{article.title}}</h5>
                                <p class="text-italic">{{article.ingress}}</p>

      </div>
    </div>

                              </div>
                                                                  <router-link style="width:100%" :to="'/article/' + article.id">
<button type='button' class='btn btn-primary btn-large btn-skip' >Les mer</button>
                                                                  </router-link>
                        </div>
                    </div>
                    
                    
                    
                    
                    </div>
        </section>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-2">
                        <img src="img/theme/promo-1.png" class="img-fluid floating">
                    </div>
                    <div class="col-md-6 order-md-1">
                        <div class="pr-md-5">
                            <h3>Spill på nett</h3>
                            <p>Til tross for at bingospill i bingohallen er morsomt og sosialt, er det ikke alltid det passer. Med vår løsning kan du spille som om du var i bingohallen.</p>
                            <ul class="list-unstyled mt-5">
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="fa fa-wifi"></badge>
                                        <h6 class="mb-0">Spill live med spill i hallen</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="fa fa-shopping-cart"></badge>
                                        <h6 class="mb-0">Forhåndskjøp til dagens spill</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="fa fa-credit-card"></badge>
                                        <h6 class="mb-0">Fyll opp spillekortet med vår sikre betalingsløsning</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section  class="section bg-secondary">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6">
                        <div class="card bg-default shadow border-0">
                            <img v-lazy="'img/assets/asset-1519097039218.png'" class="card-img-top">
                            <blockquote class="card-blockquote">
                                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                                     class="svg-bg">
                                    <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                                    <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
                                </svg>
                                <h4 class="display-3 font-weight-bold text-white">Lokale formål</h4>
                                <p class="lead text-italic text-white">Ved deltagelse i våre spill, støtter du samtidig lokale lag og foreninger.</p>
                            </blockquote>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">
                            <icon name="fa fa-heart" class="mb-5" size="lg" type="success" shadow rounded></icon>

                            <h3>Takk!</h3>
                            <p class="lead">Takket være våre mange lojale kunder, kan vi sammen bidra til økonomisk støtte til lokale lag og foreninger i hele landet.</p>
                            <p>I 2018 tilførte bingobransjen over 200 millioner kroner til gode lokale samfunnsnyttige formål i Norge. Dette bidrar til en lettere hverdag for tusenvis av lag, organisasjoner og foreninger rundt omkring i vårt vidstrakte land!</p>
                            <p>Ved å spille hos en norsk bingo, med tillatelse fra Lotteritilsynet til spill på nett, kan du være sikker på at det kommer nærmiljøet til gode.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  data () {

      return {
          newsfeed: [
          ]
      }

   }

};
</script>
<style scoped>
.features {
  margin-top: 10px;
  min-height: 400px;
  border: 1px solid #8C8C8C;
  border-radius: 7px;
  color: #4A4A4A;
  overflow: hidden;
  position: relative;
}

.bottom-right{
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>