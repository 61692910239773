<template>
  <div class="card">
    <div class="card-body">
     <div class="row">
       <div class="col-12 col-lg-2">
        <img v-if="hall.BingoLogo" :src="'img/brand/logo/' + hall.BingoLogo" />
       </div>
       <div class="col-12 col-lg-5 pl-0">
         <div class="col-12 font-weight-bold">
      {{ hall.BingoName }}
         </div>
         <div class="col-12">
      {{ hall.BingoAddress }}
         </div>
       </div>
       <div class="col-12 col-lg-5 mx-0 px-0">
           <button type="button" @click="chooseBingo(hall)" class="btn m-2 btn-success btn-sm float-right">Velg bingo</button>
       </div>
      </div> 
    </div>
  </div>
</template>
<script>
import axios from '../axios-auth'

export default {
    name: "near-bingo-hall",
    props: {
        hall: {
            type: Object,
            default: null,
            description: "Bingo Data",
            balance: null
        }

    },
    methods: {
            chooseBingo(hall) {
                console.log("chooseBingo("+hall.BingoID+")")
               this.$emit('chooseBingo', hall)
            }

    }
}
</script>