import Vue from "vue"
import App from "./App.vue"
import store from './store'

import router from "./router"
import Argon from "./plugins/argon-kit"
import VueAnalytics from "vue-analytics"

import './registerServiceWorker'


Vue.config.productionTip = false;
Vue.use(Argon);

Vue.use(VueAnalytics, {
  id: 'UA-146146392-1',
  router
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
